import React, { memo } from 'react';
import theme from '@/styles/theme';

const colors = theme.colors;

type Props = {
  color: keyof typeof colors;
};

const InfoIcon = ({ color }: Props) => {
  const fillColor = colors[color];

  return (
    <svg
      style={{ display: 'inline-block', verticalAlign: 'text-top', flexShrink: 0 }}
      xmlns="http://www.w3.org/2000/svg"
      height="18px"
      viewBox="0 0 24 24"
      width="18px"
      fill="#000000"
    >
      <path d="M0 0h24v24H0V0z" fill={fillColor} />
      <path
        d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z"
        fill={theme.colors.gold}
      />
    </svg>
  );
};

export default memo(InfoIcon);
