import React from 'react';
import ResetPassword from '@/components/organisms/Buyer/Account/ResetPassword';
import BuyerHeader from '@/components/atoms/Header/BuyerHeader';
import BuyerFooter from '@/components/atoms/Footer/BuyerFooter';
import { Wrapper } from '@/components/layout/common';
import Seo from '@/components/seo';
import { useDirectAccess } from '@/common/hooks';
import { Routings } from '@/common/routings';

const ResetPasswordPage: React.VFC = () => {
  const isDirectAccess = useDirectAccess(Routings.buyerTop.location);

  return isDirectAccess ? (
    <></>
  ) : (
    <Wrapper>
      <Seo />
      <BuyerHeader displayPattern="inquiry" />
      <ResetPassword />
      <BuyerFooter />
    </Wrapper>
  );
};

export default ResetPasswordPage;
