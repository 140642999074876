import React, { forwardRef, InputHTMLAttributes, useState } from 'react';
import styled from 'styled-components';
import theme from '@/styles/theme';
import VisibilityIcon from '../Icons/VisibilityIcon';
import VisibilityOffIcon from '../Icons/VisibilityOffIcon';

const PasswordTextFieldWrapper = styled.div`
  width: 100%;
  height: 30px;
  display: flex;
  background: #ffffff;
  border: 1px solid #cccccc;
  border-radius: 4px;
`;

const StyledPasswordTextField = styled.input`
  width: 100%;
  padding: 0 6px 0 6px;
  background: #ffffff;
  font: ${theme.fonts.normal_16_32};
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  &:hover {
    background-color: #fafafa;
  }
`;

type Props = InputHTMLAttributes<HTMLInputElement>;

const PasswordTextField = forwardRef<HTMLInputElement, InputHTMLAttributes<HTMLInputElement>>((props: Props, ref) => {
  const [mask, setMask] = useState(true);

  return (
    <PasswordTextFieldWrapper>
      <StyledPasswordTextField type={mask ? 'password' : 'text'} ref={ref} {...props} />
      <IconWrapper onClick={() => setMask(!mask)}>{mask ? <VisibilityIcon /> : <VisibilityOffIcon />}</IconWrapper>
    </PasswordTextFieldWrapper>
  );
});
PasswordTextField.displayName = 'PasswordTextField';

export default PasswordTextField;
