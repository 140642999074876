import styled from 'styled-components';

import theme from '@/styles/theme';
import { Breakpoints } from '@/constants/constants';

const Button = styled.button`
  background: ${theme.colors.background};
  border-radius: 4px;
  color: ${theme.colors.buttonTextPrimary};
  cursor: pointer;
  display: inline-block;
  font: ${theme.fonts.bold_22_33};
  padding: 16px 50px;
`;

export const PrimaryButton = styled(Button)`
  @media screen and (max-width: ${Breakpoints.sp}px) {
    width: 100%;
  }
`;

export const SecondaryButton = styled(Button)`
  background: #ffffff;
  border: 1px solid ${theme.colors.buttonBorder};
  color: ${theme.colors.buttonTextSecondary};
  font: ${theme.fonts.bold_16_24};
  padding: 19px 54px;
  @media screen and (max-width: ${Breakpoints.sp}px) {
    width: 100%;
  }
`;

export const SecondaryButtonSmall = styled(Button)`
  background: #ffffff;
  border: 1px solid ${theme.colors.buttonBorder};
  color: ${theme.colors.buttonTextSecondary};
  font: ${theme.fonts.bold_14_24};
  padding: 7px 29px;
`;

export default Button;
