import { InferType, object, string } from 'yup';
import { YupErrorMessage } from '@/constants/messages';
import { PASSWORD_VALIDATOR, REGEX_VERIFY_CODE } from '@/constants/constants';
import { multiByteCharacterValidator } from '@/utils/multiByteValidator';

export const requestVerifySchema = object({
  email: string()
    .trim()
    .required(YupErrorMessage.required)
    .max(128, YupErrorMessage.string.max)
    .test(
      'multi_byte_character_not_accepted',
      YupErrorMessage.string.multi_byte_character_not_accepted,
      multiByteCharacterValidator
    )
    .email(YupErrorMessage.string.email)
    .label('メールアドレス'),
});

export type RequestVerifyFormState = InferType<typeof requestVerifySchema>;

export const passwordResetSchema = object({
  email: string().trim().label('メールアドレス'),
  password: PASSWORD_VALIDATOR.label('新しいパスワード'),
  verifyCode: string()
    .trim()
    .required(YupErrorMessage.required)
    .matches(REGEX_VERIFY_CODE, YupErrorMessage.string.verifyCode)
    .label('認証コード'),
});

export type PasswordResetFormState = InferType<typeof passwordResetSchema>;
