/* eslint-disable @typescript-eslint/ban-types */
import { useEffect, useRef, useState } from 'react';
import { FetchStatus } from '@/models/enum/app';
import { navigate } from 'gatsby';

/**
 * 古いstateを返すCustomHook
 * @param value 対象state
 */
export function usePrevious<T extends {}>(value: T) {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

/**
 * fetchが成功した場合にtrueを返す
 * @param status 監視対象
 */
function useFetchDone(status: FetchStatus) {
  const ref = usePrevious(status);
  return ref === FetchStatus.LOADING && status === FetchStatus.DONE;
}

/**
 * fetchが成功した際に処理を実行する
 * @param status 監視対象
 * @param callback 処理
 */
export function useFetchDoneEffect(status: FetchStatus, callback: () => void) {
  let unmounted = false;
  const isDone = useFetchDone(status);
  useEffect(() => {
    if (isDone && !unmounted) {
      callback();
    }
    return () => {
      unmounted = true;
    };
  }, [status]);
}

/**
 * fetchが失敗した場合にtrueを返す
 * @param status 監視対象
 */
function useFetchFailed(status: FetchStatus) {
  const ref = usePrevious(status);
  return ref === FetchStatus.LOADING && status === FetchStatus.FAILED;
}

/**
 * fetchが失敗した際に処理を実行する
 * @param status 監視対象
 * @param callback 処理
 */
export function useFetchFailedEffect(status: FetchStatus, callback: () => void) {
  let unmounted = false;
  const isFailed = useFetchFailed(status);
  useEffect(() => {
    if (isFailed && !unmounted) {
      callback();
    }
    return () => {
      unmounted = true;
    };
  }, [status]);
}

/**
 * 直リンクで画面が表示された場合、指定の画面にリダイレクトする
 * @returns 直リンクの場合はTrue, それ以外はFalse
 */
export function useDirectAccess(redirectUrl: string): boolean {
  const [isDirectAccess, setDirectAccess] = useState(true);

  useEffect(() => {
    if (!window.previousPath) {
      navigate(redirectUrl);
    } else {
      setDirectAccess(false);
    }
  }, []);

  return isDirectAccess;
}
