import styled from 'styled-components';
import theme from '@/styles/theme';

const TextField = styled.input`
  width: 100%;
  height: 30px;
  padding: 0 6px 0 6px;
  background: #ffffff;
  font: ${theme.fonts.normal_16_32};
  border: 1px solid #cccccc;
  border-radius: 4px;
`;

export default TextField;
