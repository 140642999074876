import styled from 'styled-components';
import { Breakpoints } from '@/constants/constants';
import theme from '@/styles/theme';
import Button, { PrimaryButton, SecondaryButton } from '@/components/atoms/Button';
import TextField from '@/components/atoms/TextField';
import PasswordTextField from '@/components/atoms/PasswordTextField';
import ErrorMsg from '@/components/atoms/ErrorMessage';

export const SubTitleWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  margin-top: 61px;
  width: 100%;

  @media screen and (max-width: ${Breakpoints.sp}px) {
    padding: 0 17px;
  }
`;
export const SubTitle = styled.h2`
  color: ${theme.colors.titleText};
  font: ${theme.fonts.bold_22_33};
  padding-left: 20px;
  width: 100%;
`;
export const Wrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 1062px;
  width: 100%;

  @media screen and (max-width: ${Breakpoints.sp}px) {
    padding: 0 15px;
  }
`;

export const Area = styled.div`
  width: 100%;
  max-width: 1062px;
  text-align: center;
  border-radius: 10px;
  margin-bottom: 40px;
  background: #f7f7f7;
  padding: 40px 20px;
  @media screen and (max-width: ${Breakpoints.sp}px) {
    padding: 30px 20px;
  }
`;

export const WithdrawalWrapper = styled(Wrapper)`
  background-color: ${theme.colors.cardHeadingBackground};
  padding-top: 40px;
  padding-bottom: 40px;
  font-family: 'Noto Sans JP', sans-serif;
  margin-top: 60px;
  margin-bottom: 60px;

  @media screen and (max-width: ${Breakpoints.sp}px) {
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 10px;
  }
`;

export const Table = styled.table`
  border-collapse: collapse;
  color: ${theme.colors.text};
  margin-bottom: 61px;
  margin-top: 31px;
  width: 100%;
  @media screen and (max-width: ${Breakpoints.sp}px) {
    margin-top: 34px;
  }
`;
export const Row = styled.tr`
  border-top: 1px solid ${theme.colors.tableBorder};
  border-bottom: 1px solid ${theme.colors.tableBorder};

  @media screen and (max-width: ${Breakpoints.sp}px) {
    border: none;
    display: flex;
    flex-direction: column;

    &:not(:first-child) {
      margin-top: 30px;
    }
  }
`;
export const Th = styled.th`
  background: ${theme.colors.tableHeaderBackground};
  color: ${theme.colors.text};
  font: ${theme.fonts.bold_16_24};
  max-width: 299px;
  min-width: 120px;
  padding: 33px 20px;
  text-align: left;
  vertical-align: top;
  width: 28.16%;

  @media screen and (max-width: ${Breakpoints.sp}px) {
    background: #fff;
    border-bottom: 1px solid ${theme.colors.tableBorder};
    font: ${theme.fonts.bold_20_29};
    max-width: 100%;
    padding: 0 0 20px 0;
    vertical-align: baseline;
    width: 100%;
  }
`;
export const Td = styled.td`
  font: ${theme.fonts.normal_16_24};
  padding: 33px 28px;

  @media screen and (max-width: ${Breakpoints.sp}px) {
    padding: 20px 0 0 0;
    width: 100%;
  }
`;
export const NameContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 26px;
  width: 416px;

  @media screen and (max-width: ${Breakpoints.sp}px) {
    width: 100%;
    row-gap: 0;
    & > *:not(:last-child) {
      margin-bottom: 21px;
    }
  }
`;
export const NameRow = styled.div`
  display: flex;
  flex-direction: row;
`;
export const NamePart = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: space-between;
  @media screen and (max-width: ${Breakpoints.sp}px) {
    flex-direction: column;
    width: 50%;
    gap: 0;
    & > *:not(:last-child) {
      margin-bottom: 15px;
    }
  }
`;
export const NameLabel = styled.div`
  font: ${theme.fonts.bold_16_24};
  width: 32px;
`;
export const NameText = styled.div`
  font: ${theme.fonts.normal_16_24};
  width: 146px;
`;

export const EmailTextTd = styled(Td)`
  padding-right: 10px;
  word-break: break-all;
`;

export const EmailEditTd = styled(Td)`
  padding-left: 0;
  text-align: right;
`;

const InputTextBase = styled.input`
  border: 1px solid ${theme.colors.inputBorder};
  border-radius: 4px;
  display: inline-block;
  font: ${theme.fonts.normal_16_24};
  padding: 2px 10px;
  margin: -3px 10px -3px 0;
  @media screen and (max-width: ${Breakpoints.sp}px) {
    flex-grow: 1;
    margin: 0 10px 0 0;
  }
`;
export const InputText = styled(InputTextBase)`
  width: 408px;
  @media screen and (max-width: ${Breakpoints.sp}px) {
    width: 100%;
  }
`;
export const InputAmount = styled(InputTextBase)`
  width: 190px;
`;
export const InputYear = styled(InputTextBase)`
  width: 90px;
`;
export const FieldContainer = styled.div`
  align-items: center;
  display: flex;

  @media screen and (max-width: ${Breakpoints.sp}px) {
    width: 100%;
  }
`;
export const HeaderContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`;
export const ButtonArea = styled.div`
  margin-bottom: 30px;
  width: 100%;
`;
export const PrimaryButtonContainer = styled.div`
  text-align: center;
  width: 100%;
`;
export const ErrorMessage = styled(ErrorMsg)``;

export const GuideParagraph = styled.p`
  font: ${theme.fonts.normal_16_24};
  text-align: left;
  width: 100%;
  @media screen and (max-width: ${Breakpoints.sp}px) {
    font-size: 14px;
  }
`;
export const Required = styled.span`
  background-color: #fff;
  border: 1px solid #d1a827;
  border-radius: 4px;
  color: #d1a827;
  display: inline-block;
  font: ${theme.fonts.bold_10_20};
  height: 20px;
  line-height: 1;
  min-width: 10px;
  padding: 3px 7px;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
`;
export const WithdrawalArea = styled.div`
  text-align: right;
  width: 100%;
  margin-bottom: 20px;
`;

export const WithdrawalTextContainer = styled.div`
  text-align: center;
  color: #333333;
  font-size: 16px;
  line-height: 32px;
`;

export const SendButton = styled(Button)`
  width: 299px;
  height: 64px;
  background: #4c586f 0% 0% no-repeat padding-box;
  font: ${theme.fonts.bold_22_33};
  border-radius: 4px;
  opacity: 1;
  @media screen and (max-width: ${Breakpoints.sp}px) {
    width: 305px;
    margin-bottom: 20px;
  }
`;

export const CancelButton = styled(Button)`
  width: 198px;
  height: 64px;
  background: #ffffff 0% 0% no-repeat padding-box;
  font: ${theme.fonts.bold_16_33};
  border: 1px solid #cccccc;
  border-radius: 4px;
  align-items: center;
  opacity: 1;
  text-align: center;
  letter-spacing: 0px;
  color: #4c586f;
  display: inline-flex;
  justify-content: center;
  @media screen and (max-width: ${Breakpoints.sp}px) {
    width: 305px;
  }
`;

export const PseudoButton = styled.div`
  width: 198px;
`;

export const MainTextField = styled.p`
  font: ${theme.fonts.normal_16_32};
  text-align: center;
  margin-bottom: 20px;
  width: 100%;
  @media screen and (max-width: ${Breakpoints.sp}px) {
    font-size: 16px;
  }
`;

export const SubTextField = styled.p`
  height: 41px;
  font: ${theme.fonts.bold_12_24};
  text-align: left;
  display: inline-block;
  margin-bottom: 21px;
  @media screen and (max-width: ${Breakpoints.sp}px) {
    height: 48px;
    margin-bottom: 20px;
    font-size: 14px;
  }
`;

export const ComponentField = styled.div`
  width: 408px;
  margin: 0 auto;
  margin-bottom: 20px;
  @media screen and (max-width: ${Breakpoints.sp}px) {
    width: 100%;
  }
`;

export const ComponentTextField = styled(TextField)`
  width: 100%;
  height: 40px;
  ::placeholder {
    font: ${theme.fonts.bold_16_32};
    color: #cccccc;
  }
`;

export const PasswordField = styled.div`
  width: 408px;
  margin: 0 auto;
  margin-bottom: 30px;
  div {
    height: 40px;
    div {
      height: 38px;
    }
  }
  @media screen and (max-width: ${Breakpoints.sp}px) {
    width: 100%;
  }
`;

export const PasswordTextCustomField = styled(PasswordTextField)`
  ::placeholder {
    font: ${theme.fonts.bold_16_32};
    color: #cccccc;
  }
  @media screen and (max-width: ${Breakpoints.sp}px) {
    width: 100%;
  }
`;

export const Information = styled.p`
  padding-left: 45px;
  text-indent: -45px;
  font: ${theme.fonts.normal_12_22};
  color: #222222;
`;

export const SecondaryButtonAccount = styled(SecondaryButton)`
  margin-right: 30px;
  white-space: nowrap;
  @media screen and (max-width: ${Breakpoints.sp}px) {
    margin: 0;
  }
`;

export const PrimaryButtonAccount = styled(PrimaryButton)`
  white-space: nowrap;
`;

export const HiddenZone = styled.div`
  width: 269px;
  @media screen and (max-width: ${Breakpoints.sp}px) {
    display: none;
  }
`;

export const ButtonAreaAccountEdit = styled(ButtonArea)`
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  text-align: center;
  display: flex;

  @media screen and (max-width: ${Breakpoints.sp}px) {
    flex-direction: column-reverse;
    justify-content: center;
    margin-bottom: 0;
    & > *:nth-child(2) {
      margin-bottom: 20px;
    }
  }
`;
