import React, { useState } from 'react';
import { navigate } from 'gatsby';
import styled from 'styled-components';
import Auth from '@aws-amplify/auth';
import { Routings } from '@/common/routings';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import MediaQuery from 'react-responsive';
import {
  PasswordResetFormState,
  passwordResetSchema,
  RequestVerifyFormState,
  requestVerifySchema,
} from '@/components/organisms/Buyer/Account/ResetPassword/state';
import { Main } from '@/components/layout/common';
import {
  Area,
  ButtonArea,
  ComponentField,
  ComponentTextField,
  SendButton,
  PseudoButton,
  CancelButton,
  Wrapper,
  SubTextField,
  PasswordTextCustomField,
  PasswordField,
  MainTextField,
} from '../StyledComponents';
import { Breakpoints } from '@/constants/constants';
import PageTitle from '@/components/atoms/PageTitle';
import theme from '@/styles/theme';
import InfoIcon from '@/components/atoms/Icons/InfoIcon';
import ErrorMessage from '@/components/atoms/ErrorMessage';

const ResettingWrapper = styled(Wrapper)`
  margin: 61px auto 0px auto;
`;

const ResettingButtonArea = styled(ButtonArea)`
  display: flex;
  flex-direction: row;
  margin: 0px auto;
  justify-content: space-around;
  align-items: flex-end;
  @media screen and (max-width: ${Breakpoints.sp}px) {
    flex-direction: column-reverse;
    width: 305px;
  }
`;

const InputMailTextField = styled(MainTextField)`
  height: 56px;
`;

const InputMailCertificationTextField = styled(MainTextField)`
  height: 56px;
  @media screen and (max-width: ${Breakpoints.sp}px) {
    height: 91px;
  }
`;

const ResettingInformation = styled.p`
  font: ${theme.fonts.normal_12_22};
  color: ${theme.colors.black};
  height: 17px;
  margin-top: 30px;
  margin-bottom: 3px;
  justify-content: center;
  display: flex;
  p {
    margin-left: 12px;
  }
  @media screen and (max-width: ${Breakpoints.sp}px) {
    height: 63px;
    text-align: left;
    margin-bottom: 0px;
    display: flex;
  }
`;

const VerifyCodeField = styled(ComponentField)`
  margin-bottom: 20px;
`;

const VerifyCodeTextField = styled(ComponentTextField)`
  margin-bottom: 0px;
`;

const CompletedTextField = styled(MainTextField)`
  height: 24px;
  margin-bottom: 30px;
`;

const LoginButton = styled(SendButton)`
  @media screen and (max-width: ${Breakpoints.sp}px) {
    margin-bottom: 0px;
  }
`;

const MailField = styled(ComponentField)`
  margin-top: 30px;
  margin-bottom: 30px;
`;

const StyledErrorMessage = styled(ErrorMessage)`
  width: 100%;
  text-align: left;
  font: ${theme.fonts.normal_12_20};
  color: ${theme.colors.error};
`;

type FlowProgressType = 'requireMail' | 'confirmPassword' | 'completed';

const ResetPassword = (): JSX.Element => {
  const [progress, setProgress] = useState<FlowProgressType>('requireMail');
  const [failed, setFailed] = useState(false);

  const {
    register: requestVerifyRegister,
    handleSubmit: requestVerifyHandleSubmit,
    getValues: requestVerifyGetValues,
    formState: { errors: requestVerifyErrors },
  } = useForm<RequestVerifyFormState>({
    mode: 'all',
    resolver: yupResolver(requestVerifySchema),
  });

  const {
    register: passwordResetRegister,
    handleSubmit: passwordResetHandleSubmit,
    reset: passwordResetReset,
    getValues: passwordResetGetValues,
    formState: { errors: passwordResetErrors },
  } = useForm<PasswordResetFormState>({
    mode: 'all',
    resolver: yupResolver(passwordResetSchema),
  });

  const requestVerifyCode = async () => {
    Auth.forgotPassword(requestVerifyGetValues().email)
      .then((data) => {
        console.log(data);
        setProgress('confirmPassword');
        passwordResetReset({ email: requestVerifyGetValues().email });
      })
      .catch((err) => {
        setProgress('confirmPassword');
        passwordResetReset({ email: requestVerifyGetValues().email });
        console.warn(err);
      });
  };

  const reRegisterPassword = async () => {
    Auth.forgotPasswordSubmit(
      requestVerifyGetValues().email,
      passwordResetGetValues().verifyCode,
      passwordResetGetValues().password
    )
      .then((data: string) => {
        console.log(data);
        setProgress('completed');
        setFailed(false);
      })
      .catch((err) => {
        console.warn(err);
        setFailed(true);
      });
  };

  const onClickResend = () => {
    passwordResetReset();
    setProgress('requireMail');
    setFailed(false);
  };

  const generateMainForm = (progressVal: FlowProgressType): JSX.Element => {
    if (progressVal == 'requireMail') {
      return (
        <>
          <InputMailTextField>
            登録するメールアドレスをご入力下さい
            <br />
            認証用メールを送信します。
          </InputMailTextField>
          <MailField>
            <ComponentTextField
              id="email"
              type="email"
              placeholder="メールアドレス"
              {...requestVerifyRegister('email')}
            />
            {requestVerifyErrors?.email && <StyledErrorMessage>{requestVerifyErrors.email.message}</StyledErrorMessage>}
          </MailField>
          <ResettingButtonArea>
            <CancelButton onClick={() => navigate(Routings.login.location)}>キャンセル</CancelButton>
            <SendButton id="verify_send_btn" onClick={requestVerifyHandleSubmit(requestVerifyCode)}>
              メール送信する
            </SendButton>
            <PseudoButton />
          </ResettingButtonArea>
        </>
      );
    } else if (progressVal == 'confirmPassword') {
      return (
        <>
          <MediaQuery query="(max-width: 900px)">
            {/* スマホデザイン */}
            <InputMailCertificationTextField>
              認証用メールに記載された認証コードを
              <br />
              入力し、以下の条件を満たすパスワードを
              <br />
              設定してださい。
            </InputMailCertificationTextField>
          </MediaQuery>
          <MediaQuery query="(min-width: 901px)">
            {/* PCデザイン */}
            <InputMailCertificationTextField>
              認証用メールに記載された認証コードを入力し、
              <br />
              以下の条件を満たすパスワードを設定してださい。
            </InputMailCertificationTextField>
          </MediaQuery>
          <SubTextField>
            ・10文字以上 <br />
            ・メールアドレスと異なる
          </SubTextField>
          <VerifyCodeField>
            <VerifyCodeTextField
              id="code_input"
              type="text"
              placeholder="認証コード"
              {...passwordResetRegister('verifyCode')}
            />
            {passwordResetErrors?.verifyCode && (
              <StyledErrorMessage>{passwordResetErrors.verifyCode?.message}</StyledErrorMessage>
            )}
            {failed && <StyledErrorMessage>認証コードが違います</StyledErrorMessage>}
          </VerifyCodeField>
          <PasswordField id="input_password">
            <PasswordTextCustomField placeholder="新しいパスワード" {...passwordResetRegister('password')} />
            {passwordResetErrors?.password && (
              <StyledErrorMessage>{passwordResetErrors.password?.message}</StyledErrorMessage>
            )}
          </PasswordField>
          <ResettingButtonArea>
            <CancelButton onClick={onClickResend}>メール再送信</CancelButton>
            <SendButton id="register_password_btn" onClick={passwordResetHandleSubmit(reRegisterPassword)}>
              変更する
            </SendButton>
            <PseudoButton />
          </ResettingButtonArea>
          <ResettingInformation>
            <InfoIcon color="backgroundLight" />
            <p>
              ※認証コードが届かない場合は、メールアドレスをご確認の上再度ご入力いただくか、新規会員登録をお願いします。
            </p>
          </ResettingInformation>
        </>
      );
    } else if (progressVal == 'completed') {
      return (
        <>
          <CompletedTextField>パスワードを変更しました。</CompletedTextField>
          <ResettingButtonArea>
            <LoginButton id="completed_back_btn" onClick={() => navigate(Routings.login.location)}>
              ログインする
            </LoginButton>
          </ResettingButtonArea>
        </>
      );
    }

    return <></>;
  };

  return (
    <Main>
      <PageTitle>パスワード再設定</PageTitle>
      <ResettingWrapper>
        <Area>{generateMainForm(progress)}</Area>
      </ResettingWrapper>
    </Main>
  );
};

export default ResetPassword;
