import React, { ReactNode } from 'react';
import styled from 'styled-components';
import SlashIcon from '../Icons/SlashIcon';
import theme from '@/styles/theme';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 30px;
`;

const Title = styled.h1`
  width: 100%;
  text-align: center;
  font: ${theme.fonts.bold_30_45};
  color: #4c586f;
`;

const PageTitle = ({ children }: { children: ReactNode }) => {
  return (
    <Wrapper>
      <SlashIcon />
      <Title>{children}</Title>
    </Wrapper>
  );
};

export default PageTitle;
